import { Component, OnInit } from '@angular/core';
import { LeadFormModel } from '../../models/LeadForm';
import { NgAnimateScrollService } from 'ng-animate-scroll';

@Component({
  selector: 'app-do-i-qualify',
  templateUrl: './do-i-qualify.component.html',
  styleUrls: ['./do-i-qualify.component.css']
})
export class DoIQualifyComponent implements OnInit {

  constructor(
    private animateScrollService: NgAnimateScrollService,
    public FormValues: LeadFormModel = new LeadFormModel,
  ) { }

  ngOnInit() {
    window.scroll(0,0);
  }

  scrollToFamily(duration?:number){
    this.animateScrollService.scrollToElement('scrollToFamily', duration)
  }
  scrollToCriminal(duration?:number){
    this.animateScrollService.scrollToElement('scrollToCriminal', duration)
  }

  selectedCitizen(Citizen) {
    this.FormValues.SetCitizen(Citizen);
    this.scrollToFamily(600);
  }

  selectedFamily(Family) {
    this.FormValues.SetFamily(Family);
    this.scrollToCriminal(600);
  }

  scrollForCompletedFileds() {
    if(this.FormValues.SetCitizen != null) {
      this.scrollToFamily(600);
    }
    else if(this.FormValues.SetFamily != null) {
      this.scrollToCriminal(600);
    }
  }

  selectHealthCondition(event, value) {
    let _index = this.FormValues.HealthConditions.indexOf(value);

    if(event.target.checked && _index == -1) {
      if(value == 'None') {
        this.FormValues.resetHealthCondition();
      }
      else {
        // pop none out of health condition array
        let _none_index = this.FormValues.HealthConditions.indexOf('None');

        if(_none_index > -1) {
          this.FormValues.HealthConditions.splice(_none_index, 1);
        }
      }

      this.FormValues.setHealthCondition(value);
    }
    else {
      if(_index > -1) {
        this.FormValues.HealthConditions.splice(_index, 1);
      }
    }
  }

  checkHealthConditionExists(value) {
    let _index = this.FormValues.HealthConditions.indexOf(value);
    if(_index > -1) {
      return true;
    }
    else {
      return false;
    }
  }

}
