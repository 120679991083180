import { Injectable } from '@angular/core';

@Injectable()
export class ShowResults {
    private _IsOpen: boolean = false;

    public toggle() {
        this._IsOpen = !this._IsOpen;
    }

    public getIsOpen() {
        return this._IsOpen;
    }

    public close() {
        this._IsOpen = false;
    }

    public open() {
        this._IsOpen = true;
    }
}