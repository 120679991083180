
export const environment = {
  production: false,
  
  // need to define endpoint
  url: "https://app.fatagent.com/"
  // url: "https://dev.fatagent.com/"
  // url: "http://localhost:8000/"

};
