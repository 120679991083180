import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  GET_QUOTE_URL,
  ADD_LEAD_URL,
  SEND_QUOTE_URL,
  GET_SPA_CONTENT_URL,
  UTM_URL
 } from './api.urls';
import { UTM } from '../models/Utm.model';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {
  private QuotesType:string = "noexam";

  private _httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  };
  
  constructor (private _http: HttpClient) {}

  GetQuote (body: Object): Observable<Comment[]> {
    body["quote_type"] = this.QuotesType;
    
    let bodyString = JSON.stringify(body); 

    return this._http.post( GET_QUOTE_URL , bodyString ,{
        headers: this._httpOptions.headers,
      }) 
      .pipe(map((result: any) => result));
  }

  AddLead (body:object): Observable<Comment[]> {

    let bodyString = JSON.stringify(body); 

    return this._http.post( ADD_LEAD_URL , bodyString ,{
        headers: this._httpOptions.headers,
      }) 
      .pipe(map((result: any) => result));
  }

  SendQuoteToMe (body:object): Observable<Comment[]> {

    let bodyString = JSON.stringify(body); 

    return this._http.post( SEND_QUOTE_URL , bodyString ,{
        headers: this._httpOptions.headers,
      }) 
      .pipe(map((result: any) => result));
  }

  GetSPAContent (): Observable<Comment[]> {
    return this._http.get(GET_SPA_CONTENT_URL) 
      .pipe(map((result: any) => result));
  }

  SaveUtmData(body:UTM) {

    let bodyString = JSON.stringify(body); 
    console.log(bodyString) 

    return this._http.post( UTM_URL , bodyString ,{
        headers: this._httpOptions.headers,
      }) 
  }

  SendUtmData(UtmData: UTM){
    this.SaveUtmData(UtmData).subscribe(
      res => {        
        
      }
    )
  }

}
