import { Injectable } from '@angular/core';

@Injectable()
export class LeadFormModel {
    public ZipCode: string = null;
    public CoverageAmount: string = null;
    public Term: string = null;
    public Goal: string = null;
    public Gender : string = null;
    public Smoker : string = null; 
    public Medication : string = null;
    public Dob : string = null;
    public Citizen : string = null;
    public Family : string = null;
    public Weight : string = null;
    public HeightInFeet : number = null;
    public HeightInInch : number = null;
    public HealthConditions: any[] = [];
    public GUID: string = null;
    
    // user details section
    public FirstName :string = null;
    public LastName :string = null;
    public Email :string = null;
    public PhoneNumber :string = null;

     // for address
     public Address: string = null;
     public City: string = null;
     public State: string = null;
     public Country: string = null;

      // for utm
    public utm_source: string = null;
    public utm_medium: string = null;
    public utm_campaign: string = null;
    public utm_content: string = null;
    public utm_term: string = null;
    public referred: any = null;


     //Lead Id
     public LeadId: string = null;

    public getZipCode() {
        return this.ZipCode;
    }

    public setZipCode(value) {
        this.ZipCode= value;
    }

    public setGender(value) {
        this.Gender= value;
    }

    public setDob(value) {
        this.Dob = value;
    }

    public getDob() {
        return this.Dob;
    }

    public SetCitizen(value) {
        this.Citizen = value;
    }

    public SetFamily(value) {
        this.Family = value;
    }

    public getCoverageAmount() {
        return this.CoverageAmount;
    }

    public setCoverageAmount(value) {
        this.CoverageAmount = value;
    }

    public setHealthCondition(value) {
        this.HealthConditions.push(value);
    }

    public resetHealthCondition() {
        this.HealthConditions = [];
    }

    public setSmoker(value){
        this.Smoker = value;
    }

    public getTerm() {
        return this.Term;
    }

    public setTerm(value) {
        this.Term = value;
    }

    public getGoal() {
        return this.Goal;
    }

    public setGoal(value) {
        this.Goal= value;
    }

    public setMedication(value) {
        this.Medication= value;
    }

    public getWeight() {
        return this. Weight;
    }

    public setWeight(value) {
        this.Weight = value;
    }

    public getHeightInFeet() {
        return this.HeightInFeet;
    }

    public setHeightInFeet(value) {
        this.HeightInFeet = value;
    }

    public getHeightInInch() {
        return this.HeightInInch;
    }

    public setHeightInInch(value) {
        this.HeightInInch = value;
    }
}